.accordion-inner {
  &:first-of-type > div > .MuiAccordion-root {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-of-type > div > .MuiAccordion-root {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  & > div {
    transition:padding 0.2s;
  }
  &.expanded > div {
    padding:16px 0;
  }
}

.topbar {
  a {
    text-decoration: none;
    color: inherit;
  }
  .topbar-item {
    margin-right:30px;
  }
  .publish {
    span {
      margin-left:10px;
      color:#FFF;
    }
  }
}
